import MenuButton from '@/components_fbl/buttonComponents/MenuButton';
import { localStorageUtil } from '@/utils/CommonFunctions/localStorageUtil';
import styled from '@emotion/styled';
import EditIcon from '@mui/icons-material/Edit';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { Box, Button, List, ListItem, Stack, Typography } from '@mui/material';
// import ExpandableSearch from './ExpandableSearch';
import { setReset } from '@/store/authSlice';
import LogoutIcon from '@mui/icons-material/Logout';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import NotificationsIcon from '@mui/icons-material/Notifications';
import PeopleIcon from '@mui/icons-material/People';
import { useTheme } from '@mui/material/styles';
import { useRouter } from 'next/router';
import { useDispatch } from 'react-redux';
import Swal from 'sweetalert2';

// const NavButton = styled(Button)(({ theme }) => ({
//   borderRadius: theme.spacing(3),
//   padding: theme.spacing(0.4, 1.6),
//   border: '1px solid #A62973',
//   textDecoration: 'none',
//   background: 'transparent',
//   textTransform: 'inherit',
//   [theme.breakpoints.up('md')]: {
//     fontSize: '16px',
//   },
//   [theme.breakpoints.up('lg')]: {
//     fontSize: '18px',
//   },
//   [theme.breakpoints.up('xl')]: {
//     fontSize: '20px',
//   },
// }));

const QuickNavigation = [
  {
    title: 'Notifications',
    id: '4',
    icon: <NotificationsIcon />,
    path: '/profile/notifications',
    selected: false,
    nav: false,
    target: '_blank', // Opens link in a new tab
  },
  {
    title: 'Connections',
    id: '5',
    icon: <PeopleIcon />,
    path: '/network/connections',
    selected: false,
    nav: false,
    target: '_self', // Opens link in the same tab
  },
  {
    title: 'Invite',
    id: '8',
    icon: <MailOutlineIcon />,
    path: 'https://razorswift.page.link/rzmsrqYp2tChhBDH7',
    selected: false,
    nav: true,
    target: '_blank',
  },
];

const NavButton = styled(Button)(({ theme }) => ({
  borderRadius: theme.spacing(3),
  padding: theme.spacing(0.4, 1.6),
  border: '1px solid #A62973',
  textDecoration: 'none',
  background: 'transparent',
  textTransform: 'inherit',
  color: theme.palette.primary.main,
  whiteSpace: 'nowrap', // Prevent text from wrapping
  overflow: 'hidden', // Hide overflow text
  textOverflow: 'ellipsis', // Add ellipsis for overflow text
  width: '100%', // Full width by default
  maxWidth: '150px', // Maximum width to prevent excessive expansion
  [theme.breakpoints.up('xs')]: {
    width: '100%', // Full width on extra small devices (mobiles)
    fontSize: '12px', // Smaller font size for mobiles
  },
  [theme.breakpoints.up('sm')]: {
    width: '100%', // 75% width on small devices (small tablets)
    fontSize: '14px', // Adjust font size for small tablets
  },
  [theme.breakpoints.up('md')]: {
    width: '300px', // Fixed width of 300px on medium devices (large tablets)
    fontSize: '16px',
  },
  [theme.breakpoints.up('lg')]: {
    width: '350px', // Fixed width of 350px on large devices (desktops)
    fontSize: '18px',
  },
  [theme.breakpoints.up('xl')]: {
    width: '400px', // Fixed width of 400px on extra large devices (large desktops)
    fontSize: '20px',
  },
}));

const LogoutButton = styled(Button)(({ theme }) => ({
  borderRadius: theme.spacing(3),
  padding: theme.spacing(0.4, 1.6),
  border: '1px solid #A62973',
  textDecoration: 'none',
  background: theme.palette.primary.main,
  textTransform: 'inherit',
  color: theme.palette.common.white,
  whiteSpace: 'nowrap', // Prevent text from wrapping
  overflow: 'hidden', // Hide overflow text
  textOverflow: 'ellipsis', // Add ellipsis for overflow text
  width: '100%', // Full width by default
  maxWidth: '150px', // Maximum width to prevent excessive expansion
  '&:hover': {
    background: theme.palette.primary.dark, // Adjust the hover color if needed
    color: theme.palette.common.white, // Ensure the text remains white
  },
  [theme.breakpoints.up('xs')]: {
    width: '100%', // Full width on extra small devices (mobiles)
    fontSize: '12px', // Smaller font size for mobiles
  },
  [theme.breakpoints.up('sm')]: {
    width: '100%', // 75% width on small devices (small tablets)
    fontSize: '14px', // Adjust font size for small tablets
  },
  [theme.breakpoints.up('md')]: {
    width: '300px', // Fixed width of 300px on medium devices (large tablets)
    fontSize: '16px',
  },
  [theme.breakpoints.up('lg')]: {
    width: '350px', // Fixed width of 350px on large devices (desktops)
    fontSize: '18px',
  },
  [theme.breakpoints.up('xl')]: {
    width: '400px', // Fixed width of 400px on extra large devices (large desktops)
    fontSize: '20px',
  },
}));

function Navigation({ headerdData, noMenuIcon, menuBar }) {
  const currentUserDetails = localStorageUtil.getItem('currentUserDetails');
  const isLoggedIn = Boolean(currentUserDetails);
  const dispatch = useDispatch();
  const theme = useTheme();
  const router = useRouter();
  const handleLogout = () => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to logout',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: 'purple',
      cancelButtonColor: 'grey',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      reverseButtons: true,
      allowOutsideClick: false,
    }).then(result => {
      console.log(result, 'box');
      if (result.isConfirmed) {
        localStorage.clear();
        dispatch(setReset([]));
        router.push('/login');
      }
    });
  };
  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      flexDirection="row"
      gap={1}
      // sx={{ backgroundColor: 'red' }}
    >
      {!isLoggedIn && noMenuIcon && (
        <List
          sx={{
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {headerdData.navItems.map(item => (
            <ListItem key={item.id} sx={{ px: 1 }}>
              <NavButton
                href={item.link}
                sx={{
                  color: 'primaryPalette.black',
                  fontSize: {
                    xs: '0.75rem', // smaller font size on extra-small devices
                    sm: '0.875rem', // slightly larger font size on small devices
                    md: '1rem', // default font size on medium devices and up
                  },
                  '.MuiButton-startIcon': {
                    fontSize: {
                      xs: '18px', // smaller icon size on extra-small devices
                      sm: '20px', // slightly larger icon size on small devices
                      md: '24px', // default icon size on medium devices and up
                    },
                  },
                }}
                startIcon={<ExitToAppIcon />}
              >
                {item.name}
              </NavButton>
            </ListItem>
          ))}
        </List>
      )}
      {/* <ListItem>
        <ExpandableSearch />
      </ListItem> */}
      {isLoggedIn && noMenuIcon && false && (
        <>
          <ListItem sx={{ px: 1 }}>
            <NavButton
              href={'/profile/editprofile/profileinfo'}
              sx={{ color: 'primaryPalette.black' }}
              startIcon={<EditIcon />}
            >
              Edit Profile
            </NavButton>
          </ListItem>
          <ListItem sx={{ px: 1 }}>
            <LogoutButton
              onClick={() => handleLogout()}
              startIcon={<LogoutIcon />}
            >
              Logout
            </LogoutButton>
          </ListItem>
        </>

        // <NavButton
        //   href={'/profile/editprofile/profileinfo'}
        //   sx={{
        //     color: 'primaryPalette.black',
        //     width: {
        //       xs: 200, // full width on extra small devices (mobiles)
        //       sm: 250, // 75% width on small devices (small tablets)
        //       md: 300, // fixed width of 300px on medium devices (large tablets)
        //       lg: 400, // fixed width of 400px on large devices (desktops)
        //       xl: 500, // fixed width of 500px on extra large devices (large desktops)
        //     },
        //   }}
        //   startIcon={<EditIcon />}
        // >
        //   Edit Profile
        // </NavButton>
      )}
      {isLoggedIn && menuBar && (
        <>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-around',
              backgroundColor: 'white',
              padding: '0px 0px',
            }}
          >
            {QuickNavigation.map((item, index) => (
              <Button
                key={item.id}
                onClick={() => {
                  if (item.target === '_blank') {
                    window.open(item.path);
                  } else {
                    window.location.href = item.path;
                  }
                }}
                sx={{
                  flexDirection: 'column',
                  alignItems: 'center',
                  textTransform: 'none',
                  color: 3 === index ? 'primary.main' : 'text.secondary',
                }}
                disabled={!item.nav}
              >
                <Box
                  sx={{
                    fontSize: '1rem',
                  }}
                >
                  {item.icon}
                </Box>
                <Typography variant="caption">{item.title}</Typography>
              </Button>
            ))}
          </Box>
        </>
      )}

      {<MenuButton headerdData={headerdData} />}
    </Stack>
  );
}

export default Navigation;
